import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Menu from "./components/Menu";



// const data = [
//   {
//     id: 1,
//     name : 'Sultan',
//     email : 'sultankathat@gmail.com',
//     vehicles : {
//       car:'Honda WRV',
//       bike : 'Xpulse',
//       cycle : 'BTWIN'
//     }
//   },
//   {
//     id: 2,
//     name : 'Nazia',
//     email : 'naziakathat@gmail.com',
//     vehicles : {
//       car:'Honda City',
//       bike : 'Himalyan',
//       cycle : 'Rockrider'
//     }
//   }
// ]



function App() {

  const { handle } = useParams()
  console.log("params")
  console.log(handle)

  return (
   <BrowserRouter>
   <Routes>
     {/* <Route exact path="/" element={<Nested data={data} />} />
     <Route exact path="/menu/:resID" element={<ApiCall />} /> */}
     <Route path="/" element={<Menu />} />
       
            
    </Routes>
   </BrowserRouter>
  );
}

export default App;
