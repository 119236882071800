import React from 'react'
import SearchAppBar from './AppBar'
import CategoryItem from './Category'

const Menu = () => {
  return <>
    <SearchAppBar />
    <CategoryItem />
  </>
}

export default Menu