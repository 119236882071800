
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { blueGrey, red } from '@mui/material/colors';
import Icon from "@mdi/react";
import {
//   mdiDog,
//   mdiPalmTree,
//   mdiCat,
//   mdiCalendar,
//   mdiAccountMultiple,
  mdiSquareCircle
} from "@mdi/js";



function CategoryItem(props) {

  return (
    <Box sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}>
        
        <Typography gutterBottom variant="h6" component="div" color={blueGrey[500]}
        sx={{mx: 1}}
        >
            Starter
        </Typography>      
        
        <Divider variant='fullWidth'/>

        <Grid container sx={{mx: 1}}>
            <Grid item xs={1} sx={{  my:0.4}}>
                <Icon path={mdiSquareCircle} title="SquareCircle" size={0.8} color={red[700]} />
            </Grid>
            <Grid item xs={11} >
                <Typography  variant="body1"  color={blueGrey[800]}>        
                    Biryani
                </Typography>
            </Grid>
 
            <Grid item xs={4} >
          
                </Grid> 
       </Grid>            
          
 
       <Typography  variant="body1" sx={{mx: 1}}>
            INR 450.00
        </Typography>
        
        <Typography color="text.secondary" variant="body1" sx={{mx: 1}}>
          Pinstriped cornflower blue cotton takes you on a walk to the park or
          just down the hall.
        </Typography>       

    </Box> 
  
  );
}


export default CategoryItem;